.district-card {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    background: #7D7DFF;
    &-left{
        padding: 20px;
        border-right: 1px dashed #CECEF3;
    }
    &-right {
        padding: 20px;
        ul {
            li {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
