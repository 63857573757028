.indicator-accordion {
    .accordion-item {
        box-shadow: 0px 1px 2px #00000029;
        border-radius: 8px !important;
        border: none;
        padding: 0 15px;
        margin-bottom: 20px;
        .accordion-header{
            position: relative;
            display: flex;
            align-items: center;
            flex-flow: wrap;
            .accordion-button {
                letter-spacing: 0.07px;
                color: #131523;
                font-size: 0.5em;
                font-weight: 500;
                padding: 10px 0;
                background: transparent;
                box-shadow: none;
                &::after {
                    width: 1rem;
                    height: 1rem;
                    background-size: 1rem;
                    background-image: url('../../../assets/images/download.svg');
                }
            }
            img {
                height: 45px;
                margin-right: 15px;
            }
            .txt-black {
                letter-spacing: 0.07px;
                color: #131523;
                font-size: 0.5em;
                font-weight: 500;
                position: absolute;
                right: 30px;
                @media (max-width: 767px) {
                    position: relative;
                    right: 0;
                    padding: 10px 0;
                }
            }
        }
        .accordion-collapse {
            border-top: 1px dashed #7E84A3;
        }
    }
    .accordion-body {
        padding: 15px 0;
    } 
}

.indicator-table{
    .table>:not(:first-child){
        border-top: 1px solid #D7DBEC;
    }
    tr{
        &:last-child{
            td{
                border: none;
            }
        }
    }
    th {
        letter-spacing: 0px;
        color: #0D0D0D;
        font-size: 0.875em;
        font-weight: 500;
        border: none;
        &:nth-child(3),&:nth-child(4){
            text-align: center;
        }
        @media (max-width: 991px) {
            &:nth-child(2), &:nth-child(3) {
                min-width: 200px;
            }
        }
    }
    
    td {
        letter-spacing: 0px;
        color: #131523 !important;
        font-size: 0.813em;
        padding: 15px !important;
        &:nth-child(3),&:nth-child(4){
            text-align: center;
        }
    }
}

.indicator-score {
    h3 {
        letter-spacing: 0px;
        color: #7E84A3;
        font-size: 0.75em;
        margin: 0;
        line-height: 2;
    }
    p {
        letter-spacing: 0px;
        color: #131523;
        font-size: 0.875em;
        margin: 0;
    }
}
