.category-card-wrap{
    &.card-orange {
        border: 1px solid #E99214;

        .category-card-box.card-box{
            border-left: 1px solid #E99214;
            background: transparent;
        }
    }
    &.card-green {
        border: 1px solid #CFB601;

        .category-card-box.card-box{
            border-left: 1px solid #CFB601;
            background: transparent;
        }
    }
    &.card-red{ 
        border: 1px solid #F43B47;

        .category-card-box.card-box{
            border-left: 1px solid #F43B47;
            background: transparent;
        }
    }
    &.card-purple{ 
        border: 1px solid #A62FE5;

        .category-card-box.card-box{
            border-left: 1px solid #A62FE5;
            background: transparent;
        }
    }
    &.card-pink{ 
        border: 1px solid #CC208E;

        .category-card-box.card-box{
            border-left: 1px solid #CC208E;
            background: transparent;
        }
    }
    &.card-blue{ 
        border: 1px solid #12B8B9;

        .category-card-box.card-box{
            border-left: 1px solid #12B8B9;
            background: transparent;
        }
    }

   
}


.category-card-wrap{
    background: #fff;
    border-radius: 6px;
    .category-card{
        &-header {
            letter-spacing: 0.09px;
            color: #5A607F;
            text-align: center;
            margin: 10px 0;
            font-size: 0.875em;
            background: #fff;
            border-radius: 6px 6px 0px 0px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            overflow: hidden;
            min-height: 35px;
            padding: 0 10px;
            word-break: break-all;
        }
        &-box {
            text-align: center;
            padding: 20px 0;
            border-radius: 0px 0px 6px 6px;
        }
        &-title {
            letter-spacing: 0.05px;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 0.813em;
            font-weight: 500;
        }
        &-no {
            letter-spacing: 0.15px;
            color: #000;
            font-size: 2.25em;
            margin: 0;
            font-weight: 600;
            line-height: 1.5;
        }
    }
}
