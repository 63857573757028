.desc-black {
  text-align: justify;
  font-size: 15px !important;
}

h4.privacy-heading {
  color: #000;
  font-size: 16px;
  margin-bottom: -1px;
}

.privacy-sidebar-box {
  box-shadow: 0px 0px 10px #dddbdb;
  padding: 20px 20px 5px;
  border-radius: 10px;
  margin-top: 12px;
}
.privacy-sidebar-box li {
  margin-bottom: 18px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}
.privacy-sidebar-box a.list-item-privacy {
  display: flex;
  align-items: center;
  color: #333;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.privacy-sidebar-box a.list-item-privacy:hover {
  color: #f3372f;
}/*# sourceMappingURL=privacy.css.map */