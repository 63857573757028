.peformance-table{
    margin-top: 15px;
    overflow-y: scroll;
    max-height: 500px;
    border: 1px solid #D7DBEC;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #dbe3ee;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #1f3f60;
        border-radius: 10px;
    }
    // .text-rotate{
    //     transform: rotate(-70deg); 
    // }
    table{
        margin: 0;
        th {
            letter-spacing: 0px;
            font-size: 0.75em;
            font-weight: 400;
            height: 80px;
            min-width: 80px;
            // padding: 0;
            vertical-align: middle;
            text-align: center;
            position: sticky;
            top: 0;
            z-index: 1;
            background: #fff;
            &:first-child {
                text-align: left;
                // padding: 10px;
            }
        }
        
        td {
            letter-spacing: 0px;
            color: #131523;
            font-size: 0.75em;
            font-weight: 600;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #D7DBEC;
            &:first-child {
                text-align: left;
                min-width: 180px;
            }
            &.total-score {
                background: #7E84A3;
                text-align: center;
                color: #fff !important;
            }
        }
    }
    .table>:not(:first-child) {
        border-top: none;
    }
}





