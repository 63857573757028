.advance-search {
  .heading-sm {
    // color: #383876;
    // background: #edd1d2;
    padding: 0px 6px;
    display: inline-block;
    border-radius: 5px;
    margin: 10px 0;
    transition: 0.3s linear;

    &:hover {
      // background: #f3372f;
      // color: #fff;
    }
  }
}


.advance-search-container {
  .advance-search-input {
    padding: 10px 0 20px;

    select {
      width: 100%;
      padding: 12px 10px;
      border-radius: 7px;
      border: 1px solid #ddd;
      outline: none;
    }
  }

  .advance-search-button.reset {
    button {
      background: #5a5a5a;
      margin-left: 10px;

      &:hover {
        background: #000;
      }
    }
  }

  .advance-search-button {
    display: inline-block;
    // margin-left: 15px;
    padding: 10px 0 20px;

    button {
      color: #000;
      padding: 14px 45px;
      border: none;
      background: rgb(243, 55, 47);
      // background: linear-gradient(233deg, rgba(243, 55, 47, 1) 7%, rgba(236, 31, 199, 1) 64%);
      background: linear-gradient(200deg, rgb(241, 38, 30) 7%, rgb(95, 53, 122) 64%);
      color: #fff;
      border-radius: 5px;
      transition: 0.5s ease-in-out;

      &:hover {
        // background: linear-gradient(123deg, rgba(243, 55, 47, 1) 7%, rgba(236, 31, 199, 1) 64%);
        background: linear-gradient(123deg, rgb(241 38 30) 7%, rgb(95 53 122) 64%);
        box-shadow: 0px 0px 9px #959595;

      }
    }
  }
}

.category-card-wrap.card-school-d {
  border: 0px !important;

  .category-card-box {
    text-align: center;
    padding: 8px 0;
    border-radius: 5px;
    border: 0;
    background: linear-gradient(180deg, rgb(239, 157, 61) 0.4%, rgb(200, 54, 69) 100.2%);

    .category-card-title {
      font-size: 15px;
    }

    .category-card-no {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }

  }

}

h2.heading-sm.showing-result {
  font-size: 1.1em;
  font-weight: 500;
}

p.unicode-udise {
  font-size: 15px;
  margin-bottom: 5px;
}

h2.heading-blue.school-name {
  font-size: 1.4em;
  font-weight: 500;
  padding: 0px 0 10px;
  color: #762da5;

}

.performance-card.school-card-main {
  padding: 30px 30px;
}

a {
  text-decoration: none !important;
}

span.material-icons-round.mail {
  font-size: 16px;
  margin-right: 5px;
}

li.school-sub-h {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}

span.highlited-text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}


.advance-search-container.school-btn-b {

  padding: 0px;

  .advance-search-button {
    display: inline-block;
    padding: 0px 0 10px;
    width: 100%;
    margin-left: 0;

    button {
      padding: 15px 26px;
      width: 100%;
    }
  }

  li.school-sub-h {
    transition: 0.3s linear;

    span.highlited-text {
      transition: 0.5s linear;
    }
  }

  .btn-wrap .btn-solid{
    padding: 10px;
  }

}

span.highlited-text {
  transition: 0.3s linear;
}

li.school-sub-h:hover span.highlited-text {
  color: red;
  margin-left: 0px;
}

.blog-slider {
  width: 100%;
  position: relative;

  margin: auto;
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px 25px 22px;
  border-radius: 25px;
  height: auto;
  transition: all .3s;
  float: left;


  @media screen and (max-width: 992px) {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    min-height: 500px;
    height: auto;
    margin: 15px auto;
  }


  @media screen and (max-height: 500px) and (min-width: 992px) {
    height: auto;
  }

  &__item {
    // display: flex;
    // align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.swiper-slide-active {
      .blog-slider__img {
        img {
          opacity: 1;
          transition-delay: .3s;
        }
      }

      .blog-slider__content {
        >* {

          opacity: 1;
          transform: none;

          @for $i from 0 to 15 {
            &:nth-child(#{$i + 1}) {
              transition-delay: $i * 0.1 + 0.3s;
            }
          }

        }
      }
    }

  }

  &__img {
    width: 275px;
    flex-shrink: 0;
    height: 180px;
    background-image: linear-gradient(147deg, #ffffff 0%, #ffffff 74%);
    box-shadow: 4px 13px 30px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    transform: translateX(30%);
    overflow: hidden;
    display: flex;
    align-items: center;
    position: absolute;
    right: -1.2%;
    top: 22%;


    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(147deg, #f4f7fc 0%, #f4f7fc 74%);
      border-radius: 20px;
      opacity: 0.8;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      opacity: 0;
      border-radius: 20px;
      transition: all .3s;
    }


    @media screen and (max-width: 992px) {
      // width: 45%;
    }

    @media screen and (max-width: 768px) {
      transform: translateY(-50%);
      width: 90%;
    }

    @media screen and (max-width: 576px) {
      width: 95%;
    }

    @media screen and (max-height: 500px) and (min-width: 992px) {
      height: 270px;
    }
  }

  &__content {
    // width: 60%;
    padding-right: 25px;

    @media screen and (max-width: 992px) {
      // width: 55%;
    }

    @media screen and (max-width: 768px) {
      margin-top: -80px;
      text-align: center;
      padding: 0 30px;
    }

    @media screen and (max-width: 576px) {
      padding: 0
    }

    >* {
      opacity: 0;
      transform: translateY(25px);
      transition: all .4s;


    }

  }

  &__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px;
  }

  &__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
  }

  &__button {
    display: inline-flex;
    background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;

    @media screen and (max-width: 576px) {
      width: 100%;
    }

  }

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;

  }

  &__pagination {
    position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px !important;
    text-align: center;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
      transform: translateX(-50%);
      left: 50% !important;
      top: 205px;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 8px 0;

      @media screen and (max-width: 768px) {
        margin: 0 5px;
      }
    }

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
      display: block;
      border-radius: 10px;
      background: #062744;
      opacity: 0.2;
      transition: all .3s;

      &-active {
        opacity: 1;
        background: #fd3838;
        height: 30px;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);

        @media screen and (max-width: 768px) {
          height: 11px;
          width: 30px;
        }
      }
    }

  }

}

.blog-slider__item.school-c {
  width: 100%;

  .performance-card {
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(21, 34, 50, 0.0784313725);
    border-radius: 6px;
    padding: 15px;
  }
}

.button-schools {
  // display: flex;
  // align-items: center;
  // vertical-align: middle;
  height: 100%;
  justify-content: center;
}

.s-btn {
  width: 100%;
}

.check-btn {
  color: #f3372f;
  font-size: 18px !important;
  padding-right: 0px;
  vertical-align: middle;
}


@media screen and (min-width: 992px) and (max-width: 1400px) {
  .advance-search-container .advance-search-button button {
    padding: 12px 30px;
  }

  h2.heading-blue.school-name {
    font-size: 1.3em;
  }

  li.school-sub-h {
    font-size: 11px;
  }

  span.highlited-text {
    font-size: 11px;
  }

  p.unicode-udise {
    font-size: 12px;
  }

  .btn {
    font-size: 0.8em !important;
  }
}


.Pagination-sec {


  .pagination {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .pages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 200px;
  }

  .page-number {
    cursor: pointer;
    background-color: white;
    color: #999;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease;
  }

  .active {
    font-size: 1.3em;
    height: 40px;
    width: 40px;
    background-color: #0057b3;
    color: white;
  }

  button {
    width: 120px;
    padding: 8px 16px;
    background-color: #ffffff00;
    color: #0057b3;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button:hover {
    color: #0056b3;
  }

  button:disabled {
    background-color: #ffffff00;
    color: #ccc;
    cursor: not-allowed;
  }

  #prevPage {
    margin-right: 20px;
  }

  #nextPage {
    margin-left: 20px;
  }

  .arrow {
    font-size: 1.2em;
  }

  .nav-text {
    font-size: 0.7em;
    letter-spacing: 0.3em;
  }

}