.districtcard-list{
    .category-card-wrap{
        margin: 10px 0;
        .category-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-content {
                display: flex;
                align-items: center;
                padding: 0 15px;
                img{
                    height: 40px;
                    margin-right: 15px;
                }
            }
            &-header{
                font-size: 0.875em;
                font-weight: 600;
                letter-spacing: 0.09px;
                color: #131523;
                min-height: auto;
                margin: 0;
                text-align: left;
            }
            &-box{
                padding: 15px;
                border-radius: 0px 6px 6px 0px;
                min-width: 75px;
            }
            &-no{
                font-size: 1.5em;
                font-weight: 500;
            }
        }
    }
}