/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/

@media(max-width:868px){
  

    .portfolio .portfolio-flters li {
       
        padding: 15px 16px;
        
    }
    
    .event-card {
    
      height: 591px !important;
      overflow: hidden;
      margin-bottom: 31px;
    }
    .event-container-wrapper {
     
      padding: 10px !important;
      
    }
    }
  section#portfolio {
    /* background: #AD9832; */
    background: #bcd6fc;
  }
  .portfolio .portfolio-flters {
    padding: 0 0 20px 0;
    margin: 0 auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio .portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 15px 0px;
    font-size: 18px;
    font-weight: 500;
    margin: 0 10px;
    line-height: 1;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 1px 1px #fff;
      border-radius: 6px;
  }
  
  .portfolio .portfolio-flters li button.nav-link:hover,
  .portfolio .portfolio-flters li button.nav-link.active {
    color: #fff;
    background: #f3372f;
    
  }
  
  .portfolio .portfolio-flters li button.nav-link:first-child {
    margin-left: 0;
  }
  
  .portfolio .portfolio-flters li button.nav-link:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .portfolio .portfolio-flters li button.nav-link {
      font-size: 14px;
      margin: 0 5px;
    }
  }
  
  .portfolio .portfolio-wrap {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
  }
  
  .portfolio .portfolio-wrap img {
    transition: 0.3s;
    position: relative;
    z-index: 1;
    height: 300px;
    width: 100%;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    padding: 25px 20px;
    background-color: #fff;
    position: relative;
    border-top: 1px solid #f3f3f3;
    z-index: 2;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 0px;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 a {
    color: var(--color-default);
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 a:hover {
    color: var(--color-primary);
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: #6c757d;
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 0px;
  }
  
  .portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--color-primary);
  }
  
  .portfolio-details .swiper-button-prev,
  .portfolio-details .swiper-button-next {
    width: 48px;
    height: 48px;
  }
  
  .portfolio-details .swiper-button-prev:after,
  .portfolio-details .swiper-button-next:after {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  
  .portfolio-details .swiper-button-prev:hover:after,
  .portfolio-details .swiper-button-next:hover:after {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  @media (max-width: 575px) {
  
    .portfolio-details .swiper-button-prev,
    .portfolio-details .swiper-button-next {
      display: none;
    }
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-info h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--color-primary);
    left: 0;
    bottom: 0;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    font-size: 16px;
  }
  
  .portfolio-details .portfolio-info ul strong {
    text-transform: uppercase;
    font-weight: 400;
    color: #9c9c9c;
    font-size: 12px;
  }
  
  .portfolio-details .portfolio-info .btn-visit {
    padding: 8px 40px;
    background: var(--color-primary);
    color: #fff;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .portfolio-details .portfolio-info .btn-visit:hover {
    background: #009d8b;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item {
    padding: 30px 30px 0 30px;
    position: relative;
    background: white;
    height: 100%;
    margin-bottom: 50px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0 5px 0;
    padding-top: 20px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h4 {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    color: #009d8b;
    font-size: 26px;
    line-height: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .portfolio-details .portfolio-description .testimonial-item p {
    font-style: italic;
    margin: 0 0 15px 0 0 0;
    padding: 0;
  }
  .portfolio{
    ul.nav.nav-tabs{
        border: none;
        justify-content: center;
    padding: 0 0 16px;
    border-radius: 0;
    }
  }
  


.portfolio .portfolio-flters li button.nav-link {
    padding: 15px 35px;
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #f3372f;
    color: #f3372f;
    font-weight: 500;
}

.portfolio .portfolio-flters li button.nav-link.acative {
   color: #fff;
}