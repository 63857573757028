@import "src/styles/_mixin.scss";
@import "src/styles/_variables.scss";

.site-footer-top {
    background: #383876;
    .footer-links{
        &-heading {
            letter-spacing: 0.07px;
            color: $white;
            font-size: 1.125em;
            font-weight: 500;
            margin: 0 0 0 25px;
            padding-bottom: 15px;
        }
        ul{
            li {
                a {
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.14px;
                    color: $white;
                    text-decoration: none;
                    font-size: 0.875em;
                    line-height: 1.5;
                    width: fit-content;
                    margin-bottom: 5px;
                    &:hover{
                        color: $red;
                    }
                    .footer-links-icon {
                        margin-right: 8px;
                        span{
                            display: block;
                        }
                    }
                }
            }
        }
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
    
    .footer-logo {
        margin-bottom: 15px;
        width: 230px;
    }
    
    .footer-desc{
        p {
            letter-spacing: 0.12px;
            color: $white;
            font-size: 0.75em;
            line-height: 1.5;
            margin: 0;
            padding-bottom: 15px;
        }
    }
}


.site-footer-bottom{
    background: #2F2F65;
    padding: 10px 0;

    .copyright-text{
        p {
            letter-spacing: 0.07px;
            color: #F4F7F8;
            font-size: 0.813em;
            margin: 0;
        }
    }
    .visitor {
        @include d_flex_end;
        &-heading {
            letter-spacing: 0.14px;
            color: $white;
            font-size: 0.813em;
            margin: 0;
            font-weight: 400;
            @media (max-width: 767px) {
                font-size: 1em;
            }
        }
        &-no {
            @include d_flex_center;
            margin-left: 15px;
            span {
                border-radius: 3px;
                background: $red;
                margin: 0 2px;
                padding: 0px 5px;
                color: #fff;
                font-size: 0.938em;
                font-weight: 500;
                display: block;
                text-align: center;
            }
        }
        @media (max-width: 767px) {
            margin-top: 15px;
            justify-content: flex-start;
        }
    }
}

.report-list {
    padding-left: 45px;
    .report-list-item {
        font-size: 0.75em !important;
        position: relative;
        &:before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ffffff;
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}