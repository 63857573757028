@import "./_mixin.scss";
@import "./_icon.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "../components/Shared/Button/Button.scss";

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

a {
    cursor: pointer;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &.list {
        list-style: disc;
    }
}

.btn {
    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.bg-grey {
    background: $light-grey;
}

.ptb-30 {
    padding: 30px 0;

    @media (max-width: 991px) {
        padding: 15px 0;
    }
}

.ptb-60 {
    padding: 60px 0;

    @media (max-width: 991px) {
        padding: 30px 0;
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.mtb-15 {
    margin: 15px 0;
}

.mtb-30 {
    margin: 30px 0;

    @media (max-width: 991px) {
        margin: 15px 0;
    }
}

.mtb-60 {
    margin: 60px 0;

    @media (max-width: 991px) {
        margin: 30px 0;
    }
}

.mt-plus-2 {
    margin-top: 2rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-plus-8 {
    margin-top: 8rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-minus-02 {
    margin-top: -0.2rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-minus-6 {
    margin-top: -6.5rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-minus-8 {
    margin-top: -8rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-plus-10 {
    margin-top: 10rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-minus-11 {
    margin-top: -11rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.mt-minus-19 {
    margin-top: -19rem;

    @media (max-width: 991px) {
        margin-top: 0rem;
    }
}

.bdr-right {
    border-right: 1px solid #F1F1F5;

    @media (max-width: 767px) {
        border: none;
    }
}

.bdr-left {
    border-left: 1px solid #F1F1F5;

    @media (max-width: 767px) {
        border: none;
    }
}

.heading-sm {
    letter-spacing: 0.14px;
    color: #131523;
    font-size: 1.25em;
    font-weight: 600;
    margin: 0 15px 0 0;

    @media (max-width: 991px) {
        font-size: 0.875em;
        line-height: 1.5;
    }
}

.heading-blue {
    letter-spacing: 0.17px;
    color: #383876;
    font-size: 2.5em;
    font-weight: 400;
    margin: 0;
    text-transform: capitalize;

    @media (max-width: 991px) {
        font-size: 1.2em;
    }
}

.heading-red-center {
    letter-spacing: 0.14px;
    color: #d94148;
    font-size: 1.375em;
    font-weight: 600;
    margin: 0;

    @media (max-width: 991px) {
        font-size: 0.875em;
        line-height: 1.5;
    }
}

.desc-black {
    letter-spacing: 0px;
    color: #131523;
    font-size: 1em;
    line-height: 1.5;
    margin: 0;
    padding: 10px 0;

    @media (max-width: 991px) {
        font-size: 0.8em;
    }
}

.title-white-sm {
    letter-spacing: 0px;
    color: #CECEF3;
    font-size: 0.875em;
    margin: 0;
    font-weight: 400;
    line-height: 1.8;
}

.heading-white {
    letter-spacing: 0.07px;
    color: #FFFFFF;
    font-size: 1.125em;
    margin: 0;
}

.desc-white {
    letter-spacing: 0.07px;
    color: #FFFFFF;
    font-size: 1em;
    font-weight: 400;
    margin: 0;
}

.banner-bg {
    // background-image: url('../assets/images/banner-bg.svg');
    background-color: #D7DBEC;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner-state {
    background-image: url('../assets/images/locate-school-banner.jpg');

}




.select-wrap {
    .form-row {
        display: flex;

        .form-col {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            .form-label {
                letter-spacing: 0px;
                color: #7E84A3;
                font-size: 0.75em;
                font-weight: 500;
            }

            .form-select {
                border: 1px solid #D7DBEC;
                border-radius: 4px;
                letter-spacing: 0.1px;
                color: #5A607F;
                font-size: 0.875em;
                padding: 0.5rem 2.25rem 0.5rem 0.75rem;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        margin: 10px 10px 10px 0;
    }
}


.indicator-select {
    position: relative;

    label {
        position: absolute;
        top: -8px;
        left: 20px;
        background: #ffffff;
        padding: 0 10px;
        letter-spacing: 0.07px;
        color: #7E84A3;
        font-size: 0.813em;
    }

    .form-select {
        height: 45px;
        font-size: 0.875em;
        line-height: 26px;
        letter-spacing: 0.11px;
        color: #0D0D0D;
        padding: 0 30px;
        border: 1px solid #CBCEE3;
        border-radius: 21px;

        &:focus {
            box-shadow: none;
        }
    }
}

.download-btn {
    background: #7E84A3;
    border-radius: 4px;
    padding: 0 10px;
    @include d_flex_center;

    a {
        font-size: 0.875em;
        letter-spacing: 0.07px;
        color: #FFFFFF;
        text-decoration: none;
        @include d_flex_center;

        span {
            display: block;
            margin-right: 8px;
            font-size: 18px;
        }
    }
}

.color-striped {

    .dark-blue,
    .card-daksh {
        background: #0000FF;
        color: #fff;
    }

    .blue,
    .card-utkarsh {
        background: #5050FF;
        color: #fff;
    }

    .light-blue,
    .card-ati-uttam {
        background: #7D7DFF;
        color: #fff;
    }

    .dark-green,
    .card-uttam {
        background: #009632;
        color: #fff;
    }

    .light-green,
    .card-prachesta-1 {
        background: #96FF96;
        color: #131523;
    }

    .yellow,
    .card-prachesta-2 {
        background: #FFFF00;
        color: #131523;
    }

    .orange,
    .card-prachesta-3 {
        background: #FFC800;
        color: #131523;
    }

    .light-pink,
    .card-akanshi-1 {
        background: #FA9696;
        color: #fff;
    }

    .pink,
    .card-akanshi-2 {
        background: #FA6464;
        color: #fff;
    }

    .red,
    .card-akanshi-3 {
        background: #FA4B4B;
        color: #fff;
    }
}

.grade-list {
    .card-daksh {
        background: #0000FF;
        color: transparent;
    }

    .card-utkarsh {
        background: #5050FF;
        color: transparent;
    }

    .card-ati-uttam {
        background: #7D7DFF;
        color: transparent;
    }

    .card-uttam {
        background: #009632;
        color: transparent;
    }

    .card-prachesta-1 {
        background: #96FF96;
        color: transparent;
    }

    .card-prachesta-2 {
        background: #FFFF00;
        color: transparent;
    }

    .card-prachesta-3 {
        background: #FFC800;
        color: transparent;
    }

    .card-akanshi-1 {
        background: #FA9696;
        color: transparent;
    }

    .card-akanshi-2 {
        background: #FA6464;
        color: transparent;
    }

    .card-akanshi-3 {
        background: #FA4B4B;
        color: transparent;
    }
}

.district-card-wrap {

    .card-prachesta-1,
    .card-prachesta-2,
    .card-prachesta-3 {

        .heading-white,
        .title-white-sm,
        .desc-white {
            color: #131523;
        }

        .district-card-left {
            border-right: 1px dashed #131523;
        }
    }
}


.card-orange {
    .card-box {
        background: transparent linear-gradient(0deg, #F15656 0%, #E99214 100%) 0% 0% no-repeat padding-box;
    }
}

.card-green {
    .card-box {
        background: transparent linear-gradient(180deg, #CFB601 0%, #0F856E 100%) 0% 0% no-repeat padding-box;
    }
}

.card-red {
    .card-box {
        background: transparent linear-gradient(180deg, #F43B47 0%, #453A94 100%) 0% 0% no-repeat padding-box;
    }
}

.card-purple {
    .card-box {
        background: transparent linear-gradient(180deg, #A62FE5 0%, #4006CB 100%) 0% 0% no-repeat padding-box;
    }
}

.card-pink {
    .card-box {
        background: transparent linear-gradient(180deg, #CC208E 0%, #6713D2 100%) 0% 0% no-repeat padding-box;
    }
}

.card-blue {
    .card-box {
        background: transparent linear-gradient(180deg, #12B8B9 0%, #6B32AF 100%) 0% 0% no-repeat padding-box;
    }
}

.btn-wrap {
    .btn-solid {
        border-radius: 4px;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        background: #f3372f;
        cursor: pointer;
        border: 1px solid #f3372f;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

        &:hover {
            // background: transparent;
            color: #fff;
        }

        span {
            margin-left: 5px;
            width: 20px;
            height: 15px;
            align-items: center;
            justify-content: center;
            display: flex;
        }

    }

    .btn-bdr {
        border: 1px solid #383876;
        border-radius: 4px;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #383876;
        font-weight: 500;
        margin-right: 10px;
        cursor: pointer;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

        &:hover {
            background: #383876;
            color: #fff;
        }

        span {
            display: flex;
            width: 10px;
            height: 10px;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            font-size: 20px;
        }
    }
}

.check-icon {
    color: #00b73d
}

.screentable {
    .table {
        border: 1px solid #ccc;

        th {
            letter-spacing: 0px;
            font-size: 0.875em;
            font-weight: 500;
            background-color: #383876;
            color: #fff;
        }

        td {
            letter-spacing: 0px;
            font-size: 0.875em;
            font-weight: 400;
        }

        tbody {
            border-top: 0;
        }
    }
}


@media screen and (max-width: 1024px) {
    [data-aos] {
        pointer-events: auto !important;
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 1 !important;
    }

    html:not(.no-js) [data-aos=fade-left],
    html:not(.no-js) [data-aos=fade-right],
    html:not(.no-js) [data-aos=fade-up] {
        transform: none !important;
    }
}






.preloader-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 40%;
    left: 46%;
    z-index: 9999;
}

@media (max-width: 1199px) and (min-width:995px) {
    .site-header .navbar .navbar-nav .nav-item a{padding: 8px 10px;font-size: 0.75em;}
}

@media (max-width: 991px) and (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 830px !important;
    }

    .site-header .logo-wrap .navbar-brand img {
        width: auto;
    }

    .site-header .logo-wrap {
        padding: 10px 0 10px;
    }

    .common-banner-heading {
        font-size: 1.5em;
    }

    .common-banner-desc {
        font-size: .8em;
    }
    .same-width {
        width: 130px;
    }

    .h2, h2 {
        font-size: calc(1.2rem + .9vw);
    }
    .school-container .heading-blue{font-size: 1.2em;}
    .school-container .basic-information .information-title p{font-size: 12px;}
    .school-container .basic-information .information-title p span.information-span{font-size: 13px;}
    .school-container .basic-information .information-title.tilte-disply-align p span.information-span {
        width: calc(100% - 130px);
        word-wrap: break-word;
    }

    .basic-information-profile .profile{
        padding: 12px 20px;
    }
    .desc-black {
        font-size: 13px !important;
    }
    .btn {
        padding: 5px 5px;
        font-size: 0.8em;
    }
    .hero-banner .hero-banner-img {
        padding: 0 !important;
        margin: 0px 0;
    }

    .pgicategory-card img {
        width: 50px;
        height: 50px;
    }
    .pgicategory-card-heading{font-size: .8em;}
    ul.bullet-points li {
        margin-left: 15px;
        font-size: 12px;
        text-align: left;
        padding-left: 4px;
        margin-bottom: 8px;
        padding-right: 5px;
        line-height: 17px;
    }

    .pb-5 {
        padding-bottom: 1rem!important;
    }
    .portfolio .portfolio-flters li button.nav-link {
        padding: 10px 24px;
    }
    .portfolio .portfolio-wrap .portfolio-info h4 {
        font-size: 15px;
        padding-right: 0px;
    }
    .site-footer-top .footer-links-heading{font-size: 1em;}
    .site-footer-top .footer-links ul li a{font-size: 0.65em;}
    .material-icons-round{font-size: 16px;}
    .site-footer-top .footer-desc p {
        font-size: 0.6em;
        line-height: 1.5;
        padding-bottom: 12px;
    }
    .site-footer-top .footer-logo {
        margin-bottom: 12px;
        width: 156px;
    }
    .advance-search-container .advance-search-input select{padding: 5px 10px;}
    .state-card-top.card-details{padding: 10px 15px;}
    .state-card-top.card-details .state-card-title {
        font-size: 0.6em;
        word-break: break-word;
        padding-left: 10px;
    }
    .state-card-top.card-details img {
        width: 30px;
        height: 30px;
        margin-right: 25px;
    }
    h2.heading-blue.school-name {
        font-size: 1.1em;
    }

    .advance-search-container.school-btn-b .btn-wrap .btn-solid {
        padding: 7px 5px;
    }
    li.school-sub-h {
        font-size: 12px;
    }
    span.highlited-text{
        font-size: 13px;
    }

    .basic-information-profile .profile-icon {
        display: block;
        text-align: center;
        width: 100%;
    }
    .print-icon-box .backbuttondata{margin-top: 0px;}
    .school-information .col-20 {
        width: 25%;
    }
    .Infrastructure .profile-text .image-box {
        height: 65px;
        width: 65px;
        padding: 12px;
    }
    .Infrastructure .profile-text .studentNumber{font-size: 11px;}
    .school-information .profile-text{min-height: 95px;}
    .school-information .profile-text p {
        line-height: 20px;
        font-size: 14px;
    }
}

.hero-banner.sqaf-banner .hero-banner-img.hidden-xs {
    display: block !important;
}


.red-bg {background: #f3372f; color: #fff;}
.red-bg:hover{background: none; border-color: #f3372f; color: #f3372f;}


/************12 Feb 24*********************/
.header-top{background-color: #02263c;}
.navbar{background-color: #78356c;}
.site-header .navbar .navbar-nav .nav-item a{color: #fff;}
.heading-blue{position: relative; margin-bottom: 20px; color: #000; font-size: 30px; font-weight: 600;}
.heading-blue:after{ position: absolute;  left: 0; bottom: -8px; content: ""; height: 3px; min-width: 60px; background: #e13833}
.vision-mission-card { padding: 60px 0 !important;  background: none !important;}
.aboutpgi { padding: 30px 0 !important;  background:#fff ; position: relative;}

#PM_Features.pgicategory  {/*background: #C4D4EF !important;*/ padding: 60px 0 !important;  background-image: url('../assets/images/bgox-section.jpg');}
#PM_Features.pgicategory .heading-blue {color: #000;}
#PM_Features.pgicategory .heading-blue:after {background:#e13833 ;}
.portfolio .heading-blue { text-align: left !important ; padding: 0 !important;}
.portfolio .heading-blue::after{ min-width:60px;}
.performance {   padding:40px 0; background-image: url('../assets/images/map-bg.jpg'); background-repeat: repeat;}
.performance .heading-red-center{color: #000;}
.performance .performance-card {padding: 20px 30px 20px;}
.performance .heading-blue {margin-bottom: 35px; color: #fff;}
.performance .heading-blue:after {background: #fff;}
.performance .btn {color: #fff !important; background-color: #e13833;  background: #e13833; border-color: #e13833;}
.performance .btn:hover {background-color: #fff; border-color: #fff; color: #000 !important; }
.performance .readMorehome a{color: #fff !important; background: #e13833; border-color: #e13833; padding: 6px 12px; border-radius: 4px;}
.performance .readMorehome a:hover{ color: #000 !important; background: #fff; border-color: #fff;}

.pgicategory-card{background: #ceefd0; padding: 30px; text-align: left; min-height: 329px;}
.pgicategory-card.pgicategory-card-2 {background: rgb(255, 214, 192);}
.pgicategory-card-heading {font-size: 22px; margin: 15px 0 10px;}
.pgicategory-card:hover { background: #e13833; color: #fff; transition: all 0.4s !important;}
.pgicategory-card.bg-white {min-height: 382px;}
.pgicategory-card.bg-white:hover {background-color: #e13833 !important;}
.pgicategory-card.bg-white .pgicategory-card-heading {font-size: 16px;}
#gallery{border-top: solid 1px #f5f3f3;}
.portfolio {background-color: #ffd6c0  !important; }
.portfolio .portfolio-flters li{ border: none !important; box-shadow: none !important;}
.portfolio .portfolio-wrap{background-color: #fff;}
.portfolio .portfolio-flters li button.nav-link {background: #fff; color: #000; border: none;}

.pgicategory-card .icons  {
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: block;
    text-align: center;
    line-height: 58px;
}

.pgicategory-card .icons img { width: 50px;height: 50px;}

#PM_Features.pgicategory .pgicategory-card .icons{   background:#ededed;}
.pgicategory-card:hover img{filter: initial;}

.img-new{width:  auto !important; max-width:initial !important;}
.highcharts-background {background: none !important;     fill: none;}
.red-bdr-btn {  color: #fff; border: 1px solid;  background: #f3372f;}
.red-bdr-btn:hover{  color: #fff;  border: 1px solid; background: #000;}
.aboutpgi .about-img{position: absolute; left: -30px; bottom: 5px; width: auto;}
.about-img1{position: absolute; right: -34px; bottom: 5px; width: auto;}

.gallery-2  {/*background: #C4D4EF !important;*/ padding: 60px 0 !important;  background-image: url('../assets/images/bgox-section.jpg');}
.gallery-2 .portfolio {background-color:initial !important;}

@media (max-width: 991px) {
    #PM_Features.pgicategory  {padding: 30px 0 !important;}
    .performance {padding:30px 0 15px !important;}
    .vision-mission-card { padding: 30px 0 !important;}
    .aboutpgi .about-img{display: none;}
    .about-img1{display: none;}
    .site-header .navbar .navbar-nav .nav-item a{color: #000;}
    .site-header .navbar .navbar-nav .nav-item{margin-right: 0;}
    .hero-banner .hero-banner-img{margin: 0;}
}


@media (max-width: 575px) {
    .portfolio .portfolio-flters li { margin: 0 2px;}
    .portfolio .portfolio-flters li button.nav-link{padding: 12px 15px;}
    .desc-black {font-size: 14px !important;}
    .pgicategory-card-heading {font-size: 16px; margin: 12px 0 10px;}
    .aboutpgi .common-content{margin-top: 20px;}
    .btn{font-size: 14px;}
}






